<template>
  <div class="avue-contail"
       :class="{'avue--collapse':isCollapse}">
    <div class="avue-header">
      <!-- 顶部导航栏 -->
      <top/>
    </div>

    <div class="avue-layout">
      <div class="avue-left">
        <!-- 左侧导航栏 -->
        <sidebar/>
      </div>
      <div class="avue-main">
        <!-- 顶部标签卡 -->
        <tags/>
        <!-- 主体视图层 -->
        <el-scrollbar style="height:100%">
          <keep-alive>
            <router-view class="avue-view"
                         v-if="$route.meta.$keepAlive"/>
          </keep-alive>
          <router-view class="avue-view"
                       v-if="!$route.meta.$keepAlive"/>
        </el-scrollbar>

      </div>
    </div>
    <div class="avue-shade"
         @click="showCollapse"></div>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  import tags from './tags'
  import top from './top/'
  import sidebar from './sidebar/'
  import admin from '@/util/admin';
  import {validatenull} from '@/util/validate';
  import {getStore} from '@/util/store.js';

  export default {
    components: {
      top,
      tags,
      sidebar
    },
    name: 'index',
    data() {
      return {
        //刷新token锁
        refreshLock: false,
        //刷新token的时间
        refreshTime: '',
        // 计时器
        timer: '',
        first:true
      }
    },
    created() {
      //实时检测刷新token
      let that = this;
      this.refreshToken(); 
      this.loadNewToken();

      //检测用户是否在使用，如果长时间不使用，退出
      document.addEventListener('mousemove', () => {
       
        localStorage.setItem('lastActiveTime', new Date().getTime());
      });
      document.addEventListener('keypress', () => {
        localStorage.setItem('lastActiveTime', new Date().getTime());
      });


      const timer1 = setInterval(() => {
      
      // Check if the user has been inactive for more than 10 minutes
      const lastActiveTime = localStorage.getItem('lastActiveTime');
      const currentTime = new Date().getTime();
      if (currentTime - lastActiveTime > 10 * 60 * 1000) {

       
        clearInterval(timer1);
        localStorage.removeItem('lastActiveTime');
        that.$store.dispatch('LogOut')
        this.$alert('检测到您已好久未操作,已为您退出登录','提示',{
          confirmButtonText: '确定',
          callback: action => {
            that.$router.push({path: '/login'});

          }
        })

       
      }
      
    },  1*60*1000);

    },
    destroyed() {
      clearInterval(this.refreshTime)
      clearInterval(this.timer)
      this.disconnect()
    },
    mounted() {
      this.init()
    },
    computed: mapGetters(['userInfo', 'isLock', 'isCollapse', 'website', 'expires_in']),
    props: [],
    methods: {
      showCollapse() {
        this.$store.commit('SET_COLLAPSE')
      },
      // 屏幕检测
      init() {
        this.$store.commit('SET_SCREEN', admin.getScreen())
        window.onresize = () => {
          setTimeout(() => {
            this.$store.commit('SET_SCREEN', admin.getScreen())
          }, 0);
        }
      },
      /**
       * 因为oath2 的那个限制不允许重复登陆有问题,只能登陆后刷新一下token,把原来的登陆token变为无效
       */
      loadNewToken(){
        // if(this.first)
        // {
        //   this.refreshLock = true
        //   this.first = false;
        //   this.$store
        //     .dispatch('RefreshToken')
        //     .catch(() => {
        //       clearInterval(this.refreshTime)
        //     });
        //     this.refreshLock = false

        // }
          
      },

      // 实时检测刷新token
      refreshToken() {
        this.refreshTime = setInterval(() => {
          const token = getStore({
            name: 'access_token',
            debug: true
          });

          if (validatenull(token)) {
            return;
          }

          if (this.expires_in <= 1000 && !this.refreshLock ) {
            this.refreshLock = true
            this.first = false;
            this.$store
              .dispatch('RefreshToken')
              .catch(() => {
                clearInterval(this.refreshTime)
              });
            this.refreshLock = false
          }
          this.$store.commit('SET_EXPIRES_IN', this.expires_in - 10);
        }, 10000);
      },
      disconnect() {
        if (this.stompClient != null) {
          this.stompClient.disconnect();
          console.log('Disconnected');
        }
      }
    }
  }
</script> 
 