<template>
  <div class="top-menu">
    <el-menu :default-active="topMenuIndex+''"
             mode="horizontal"   v-if="firstMenusShow"
             :active-text-color="theme">
       
      <template v-for="(item,index) in firstMenus ">
        <el-menu-item :index="index+''"
                      @click.native="openMenu(index)"
                      :key="index"
                      style="font-size: 15px;font-weight: bold">
          <template slot="title">
            <i :class="item.icon"></i>
            <span>{{item.label}}</span>
          </template>
        </el-menu-item>
        
        
      </template>
    
      <template >
        <el-dropdown split-button type="info" :style="{'margin-top': '10px'}" size="small" icon="el-icon-message" circle style="font-size: 15px;font-weight: bold" >
          更多
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(item,index) in nextMenus " :key="index" :icon="item.icon" @click.native="openMenu(index + 4)"><span>{{item.label}}</span></el-dropdown-item> 
          </el-dropdown-menu>
        </el-dropdown>
        
        
      </template> 
    </el-menu>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: 'top-menu',
  data() {
    return { 
      firstMenusShow : false
       
    };
  },
  created() {
  },
  computed: {
    ...mapGetters(['tagCurrent', 'menu', 'theme', 'topMenuIndex']),
    // firstMenu: function () {
    //     return this.menu.filter(m => product.onSale)
    //   }
    // 过滤掉属性名和属性值为空的数据规格项目
    firstMenus() {
      //debugger;
      let firstMenus = [];
      this.menu.forEach((item, index, Array) => {
        if (index < 4) {
          firstMenus.push(item);
        }
      }); 
      return firstMenus ;
      
    },  nextMenus() {
      let nextMenus = [];
        this.menu.forEach((item, index, Array) => {
          if (index < 4) {
            
          } else {
            nextMenus.push(item);
          }
        }); 
        return nextMenus
    }
  },
  mounted() {
    // let firstMenus = [];
    // let nextMenus = [];
    // this.menu.forEach((item, index, Array) => {
    //   //   console.log(arrayTest);
    //    if (index < 4) {
    //     firstMenus.push(item);
    //    } else {
    //     nextMenus.push(item);
    //    }
    // }); 
    // debugger
 
     
     this.firstMenusShow = true;
    this.$forceUpdate();
  },
  methods: {
    openMenu(index) {
      this.$store.commit('SET_TOP_MENU_INDEX', index)
    } 
  }
};
</script>
